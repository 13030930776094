const apiKey = 'AIzaSyAbmqRQ0LVm-Kl8uUkXJ8bVMKKcf1tIVmc';
const storageBucket = 'jason-ren.appspot.com';
const messagingSenderId = '536962448718';
const appId = '1:536962448718:web:1bd59b62bccceb3479321f';
const measurementId = 'G-7BR5FGXNGL';
const firebaseLink = 'https://firebasestorage.googleapis.com/v0/b/jason-ren.appspot.com/o/JasonRenResume.pdf?alt=media&token=e7d1e386-ee9c-46fa-9cdc-72ef882b7efe';

module.exports = {
  firebaseLink,
  apiKey,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};
