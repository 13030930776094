import ReactDOM from 'react-dom';
import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { App } from './App';
import {
  apiKey, appId, measurementId, messagingSenderId, storageBucket,
} from './config';
import './index.css';
import * as Sentry from '@sentry/react';

const firebaseConfig = {
  apiKey,
  authDomain: 'jason-ren.firebaseapp.com',
  projectId: 'jason-ren',
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

Sentry.init({
  dsn: 'https://74db563ca2e428785ddbcf6e6609c652@o4506921139503104.ingest.us.sentry.io/4506921142779904',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/localhost:1234\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const app = document.getElementById('app');

ReactDOM.render(
  <App />,
  app,
);
